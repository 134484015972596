import React from 'react'

export default function VideoRowBox() {
    return <>
        <div className="video-course-box dashboard-row-box">
            <div className="image">
                <img src="" alt="" className='w-100 h-100' />
            </div>
            <p className='title separate'>
                عنوان الوحدة
            </p>
            <div className="quiz separate">
                <i className="fa-solid fa-paste"></i>
                <p className='m-0'>
                    4 امتحان
                </p>
            </div>
            <div className='homework separate'>
                <i className="fa-solid fa-book-open"></i>
                <p className='m-0'>
                    4 واجب
                </p>
            </div>
            <div className='views '>
                <i className="fa-solid fa-play"></i>
                <p className='m-0'>
                    1432 مشاهدة
                </p>
            </div>
  
            <div className="date-setting">
                <div className='date'>
                    <p className='m-0'>
                        التاريخ
                    </p>
                    <p className='m-0'>
                        3/30/2024
                    </p>
                </div>
                <div className="controll-btns">
                    <button className='setting'>
                        <i className="fa-solid fa-gear"></i>
                    </button>
                    <button className='delete'>
                        <i className="fa-regular fa-trash-can"></i>
                    </button>
                </div>
            </div>
        </div>
    </>
}
