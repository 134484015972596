import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import config from '../../../config'
import { auth } from '../../../Context/AuthStore'
import Placeholder from '../../../Components/Used/Placeholder'
import { Link, useParams } from 'react-router-dom'

export default function DashboardShowStudentAnswers() {

    const { userAuth } = useContext(auth)
    const { row_id } = useParams()
    const [studentAnswersData, setstudentAnswersData] = useState(false)

    const getStudentExamResults = () => {
        axios.get(`${config.basURL}dashboard/exam-questions-results/${row_id}`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            console.log(res.data)
            setstudentAnswersData(res.data)
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(()=>{
        getStudentExamResults()
    } , [userAuth])

  return <>

  <div className='container-fluid dashboard-show-student-answers'>
    <h2>
        اجابات الطالب : {studentAnswersData && studentAnswersData[0].student.name}
    </h2>
    <div className="answers">
        {
            studentAnswersData ? ( studentAnswersData.length > 0 ? studentAnswersData.map((answer , index) => <div className="answer-box" key={answer.id}>
                <h3 className='text-center py-3'> السؤال رقم : { index + 1 } </h3>
                <h5 className='question-title' dangerouslySetInnerHTML={{'__html' : answer.question_text}} ></h5>
                <div className="title">
                    <p> اجابه الطالب </p>
                    <p> الاجابه الصحيحه </p>
                </div>
                <ul className='student-answer'> 
                    <li className={`${answer.grade > 0 ? 'right' : 'wrong'}`} dangerouslySetInnerHTML={{'__html': answer.answer_text}}></li>
                    <li dangerouslySetInnerHTML={{'__html': answer.correct_answer_text}}></li>
                </ul>
            </div>) : <p className='text-center py-2 w-100'> لا يوجد اجابات </p> ) : <Placeholder />
        }
    </div>
  </div>
  
  </>
}
