import React, { useEffect, useRef, useState } from 'react'
import Swal from 'sweetalert2';

export default function Video({ videoLink, firstTime, sendWatchTry, setIntervalID, setWatchTime, intervalID, sendWatchTime, avilabile, watchTime }) {

    const videoRef = useRef(null);
    const [cumulativeTime, setCumulativeTime] = useState(0);
    const [lastTime, setLastTime] = useState(0);
    const [hasLogged, setHasLogged] = useState(false);

    const threshold = 0.3; // 30%

    useEffect(() => {
        const video = videoRef.current;

        const handleTimeUpdate = () => {
            const currentTime = video.currentTime;
            const deltaTime = currentTime - lastTime;

            if (deltaTime > 0) {
                setCumulativeTime((prevTime) => prevTime + deltaTime);
            }

            setLastTime(currentTime);

            const duration = video.duration;
            const targetTime = duration * threshold;

            // console.log(cumulativeTime)
            setWatchTime(cumulativeTime)

            if (!hasLogged && cumulativeTime >= targetTime) {
                sendWatchTry()
                console.log('User has cumulatively watched 30% of the video.');
                setHasLogged(true); // Ensure the log happens only once
            }
        };

        const handleSeeking = () => {
            setLastTime(video.currentTime);
        };

        if (video) {
            video.addEventListener('timeupdate', handleTimeUpdate);
            video.addEventListener('seeking', handleSeeking);
        }

        // Cleanup event listeners on component unmount
        return () => {
            if (video) {
                video.removeEventListener('timeupdate', handleTimeUpdate);
                video.removeEventListener('seeking', handleSeeking);
            }
        };
    }, [lastTime, cumulativeTime, hasLogged]);

    useEffect(() => {
        const pauseHandler = () => {
            sendWatchTime()
        }
        videoRef.current.addEventListener('pause', pauseHandler)
        return () => {
            videoRef.current.removeEventListener('pause', pauseHandler)
        }
    }, [watchTime])

    // // video end handler
    useEffect(() => {
        const onEndedVideo = () => {
            sendWatchTime();
        }
        videoRef.current.addEventListener('ended', onEndedVideo)
        return () => {
            try {
                videoRef.current && videoRef.current.removeEventListener('ended', onEndedVideo)
            } catch (err) {
                console.log(err)
            }
        }
    }, [watchTime])

    useEffect(() => {
        if (!avilabile) {
            Swal.fire({
                text: 'انتهت مشاهداتك لهذا الدرس',
                icon: 'info'
            })
        }
    }, [avilabile])

    return <>
        {
            avilabile ? <video ref={videoRef} className='w-100 rounded' controls controlsList="nodownload">
                <source src={videoLink} type='video/mp4' />
            </video> : <div className="alert alert-info text-center">انتهت مشاهداتك للدرس</div>
        }

    </>
}
