import React from 'react'
import avatar from "../../../assets/avatar.svg"

export default function StudentRow({data}) {
    return <>
        <div className='student-row'>
            <div className="image">
                <img src={avatar} alt="" className='w-100 h-100' />
            </div>
            <p className='student-name'>
                {data.name}
            </p>
            <p className='serial-number'>
                345075
            </p>
            <p className='phone-number'>
                {data.user.username}
            </p>
            <p className='phone-number'>
                {data.parent_phone}
            </p>
            <p className='student-email'>
            {data.user.email}
            </p>
            <p className='device-type'>
                Phone
                <i className="fa-solid fa-mobile-screen-button me-2"></i>
            </p>
        </div>
    </>
}
