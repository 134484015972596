import React from "react";
import lightLogo from "../assets/italic/inas-01.png";
import companyLogo from "../assets/company-logo.png";

export default function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="contact-section">
            <div className="footer-logo">
              <img className="w-100 h-100" alt="" src={lightLogo} />
            </div>
            <h4 className="text-center">تواصل معنا عبر</h4>
            <div className="row py-2 w-100 ">
              {/* <div className='email col-lg-3 col-12 text-start'>
                        AhmedFadl@gmail.com
                        mahmoud-elgharabawy.com
                        <i className="fa-regular fa-envelope"></i>
                    </div> */}
              <div className="col-lg-3 col-12 text-end">
                01099020698
                <i className="fa-solid fa-phone-flip"></i>
              </div>
              {/* <div className="col-lg-3 col-12 text-end">
                01119467097
                <i className="fa-solid fa-phone-flip"></i>
              </div> */}
            </div>
            <div className="footer-social-media py-1">
              <a
                href="https://www.facebook.com/ilprimo01?mibextid=JRoKGi"
                target="_blank"
              >
                <i className="fa-brands fa-facebook"></i>
              </a>
              <a
                href="https://www.instagram.com/signora.inas?igsh=cm9iZG80anNxc29w&utm_source=qr"
                target="_blank"
              >
                <i className="fa-brands fa-instagram"></i>
              </a>
              <a
                href="https://youtube.com/@user-qm1nj6gy3r?si=ZJWuxGdbSGMj9SI3"
                target="_blank"
              >
                <i className="fa-brands fa-youtube"></i>
              </a>
              <a
                href="https://www.tiktok.com/@signora.inas?_r=1&_d=dmgal72kfe2ci0&sec_uid=MS4wLjABAAAAdoWj9OLZLBQ7PTaD6inos7Y-prpPoAHnecu3PKovQeULjeiKU2dnMwo0kKd867ei&share_author_id=7334476573518693381&sharer_language=en&source=h5_m&u_code=ecfgkd0afa33ii&ug_btm=b8727,b0&social_share_type=4&utm_source=copy&sec_user_id=MS4wLjABAAAAdoWj9OLZLBQ7PTaD6inos7Y-prpPoAHnecu3PKovQeULjeiKU2dnMwo0kKd867ei&tt_from=copy&utm_medium=ios&utm_campaign=client_share&enable_checksum=1&user_id=7334476573518693381&share_link_id=03C0609A-5558-4234-B620-41136CAAECC3&share_app_id=1233"
                target="_blank"
              >
                <i className="fa-brands fa-tiktok"></i>
              </a>
            </div>
          </div>
          <div className="copyright">
            <div className="copyright-logo">
              <img className="w-100 h-100" src={companyLogo} alt="" />
            </div>
            <p className="text-white">
              حقوق النشر © 2024 لشركة Easy Tech . كل الحقوق محفوظة.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}
