import React from "react";
import useQueryParams from "../Hooks/useQueryParams";

// to check if url have query params or not

export default function CheckParams(props) {
  let { unit, lesson } = useQueryParams();

  return (
    <>
      {unit && lesson ? (
        props.children
      ) : (
        <div
          className="container d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        ></div>
      )}
    </>
  );
}
