import React, { useContext, useEffect, useState } from "react";
import { auth } from "./../Context/AuthStore";
import { Navigate } from "react-router-dom";
// this route protection to not allow auth users to navigate to login and register pages

export default function GuestGourd(props) {
  let { userAuth } = useContext(auth);

  return <>{userAuth ? <Navigate to={"/user-profile"} /> : props.children}</>;
}
