import React from "react";
import "../about/about.css";
import AOS from "aos";
import inas from "../../../../assets/italic/inas.png";
import inas1 from "../../../../assets/italic/pisa.gif";
import inas2 from "../../../../assets/italic/colosseum.gif";
import { Link } from "react-router-dom";
export const About = () => {
  AOS.init();
  return (
    <>
      <section className="about">
        <div
          data-aos="fade-right"
          data-aos-duration="1000"
          className="about-left"
        >
          <img className="inas" src="" alt="" srcSet={inas} />
          <img className="banner" src="" alt="" srcSet={inas1} />
          <img className=" banner-left" src="" alt="" srcSet={inas2} />
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="1000"
          className="about-right"
        >
          <h4>منصة السنيورة في اللغة الإيطالية </h4>
          <h1>لشرح مادة اللغة الإيطالية للمراحل الثانوية </h1>
          <span className=" mark w-25 h-25 text-bg-info"></span>
          <p className="fs-5">هل تحتاج المساعدة في اللغة الإيطالية ؟</p>
          <Link to="/Auth">إنضم الينا الأن </Link>
        </div>
      </section>
    </>
  );
};
