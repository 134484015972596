import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { auth } from "../Context/AuthStore";
import config from "../config";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export default function LessonByCode() {
  const [updateCode, setUpdateCode] = useState({
    code: "",
  });
  const { userAuth } = useContext(auth);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const updateCodeFunc = (e) => {
    let data = { ...updateCode };
    data.code = e.target.value;
    setUpdateCode(data);
  };

  const checkCode = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axios
      .get(`${config.basURL}codes/view-lesson-by-code/${updateCode.code}`, {
        headers: {
          Authorization: config.ApiKey,
        },
      })
      .then((res) => {
        setIsLoading(false);
        if (res.data.view_counter === 0 && res.data.view_counter != undefined) {
          Swal.fire({
            text: "انتهت مشاهداتك للدرس باستخدام هذا الكود",
            icon: "error",
          });
        } else {
          navigate(`/watch-lesson-by-code/${updateCode.code}`);
        }

        // console.log(res.data)
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <div className="lesson-by-code">
        <form action="" onSubmit={checkCode}>
          <div className="right">
            <input
              type="text"
              className="form-control"
              placeholder="ادخل الكود"
              aria-label="Username"
              aria-describedby="basic-addon1"
              name="code"
              required
              onChange={updateCodeFunc}
            />
            <button>
              {isLoading ? (
                <i className="fa-solid fa-circle-notch fa-spin"></i>
              ) : (
                <>
                  <i className="fa-solid fa-play"></i>
                  شاهد الدرس
                </>
              )}
            </button>
          </div>
          <div className="left">
            <i className="fa-solid fa-play"></i>
            <h4>شاهد دروسك من دون التسجيل بالمنصه</h4>
          </div>
        </form>
      </div>
    </>
  );
}
