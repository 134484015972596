import React, { useContext, useEffect, useRef, useState } from 'react'
import avatar from "../../../assets/avatar.svg"
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import config from '../../../config'
import { auth } from '../../../Context/AuthStore'
import Placeholder from '../../../Components/Used/Placeholder'
import CSVMake from '../../../CSVMake'
import Validator from '../../../Validator'
import Swal from 'sweetalert2'

export default function CategoriesSubscriptions() {

    const navigation = useNavigate();
    const {place_id , place_name} = useParams()


    const { userAuth } = useContext(auth)
    const [studentsData, setStudentsData] = useState(false)
    const [nextPage, setNextPage] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [searchBy, setsearchBy] = useState('username')
    const [data, setData] = useState(false);
    const [searchParam, setsearchParam] = useState('')
    const [resultBySerach, setresultBySerach] = useState(false)
    const [studentCount, setstudentCount] = useState(0)
    const [pageCount, setpageCount] = useState(1)
    const [pages, setpages] = useState([])

    const selectBox = useRef()

    const updateSearchParam = (e) => {
        setsearchParam(e.target.value)
    }

    const updateSearchBy = (e) => {
        if (e.target.value === 'all') {
            getStudentsData()
            setsearchBy({})
            selectBox.current.value = ''
            return
        }
        setsearchBy(e.target.value)
    }

    const getData = () => {
        axios.get(`${config.basURL}student/student-extra`, {
            headers: {
                'Authorization': config.ApiKey
            }
        }).then((res) => {
            setData(res.data);
        }).catch((err) => {
            console.log(err)
        })
    }

    const getStudentsData = () => {
        setStudentsData(false)
        axios.get(`${config.basURL}dashboard/students-search?page=${currentPage}&place=${place_id}`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then((res) => {
            let data = res.data.results
            let pagesCount = Math.ceil(res.data.count / 100)
            let paginationPages = [...pages]
            paginationPages = []

            for (let i = 1; i <= pagesCount; i++) {
                paginationPages.push('t')
            }
            setpages(paginationPages)
            setpageCount(pagesCount)
            setstudentCount(res.data.count)
            setStudentsData(data)
            res.data.next ? setNextPage(true) : setNextPage(false)
        }).catch((err) => {
            console.log(err)
        })
    }

    const searchStudent = (e) => {
        e && e.preventDefault();

        setStudentsData(false)
        setCurrentPage(1)
        let validation = new Validator;
        validation.setKey('username').setValue(searchParam).required();

        if (validation.haveError) {
            Swal.fire({
                text: 'ادخل رقم الهاتف لتتمكن من البحث',
                icon: 'error'
            })
            return;
        }

        axios.get(`${config.basURL}dashboard/students-search?${searchBy}=${searchParam}&page=${currentPage}&place=${place_id}`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            console.log(res.data)
            let pagesCount = Math.ceil(res.data.count / 100)
            let paginationPages = [...pages]
            paginationPages = []

            for (let i = 1; i <= pagesCount; i++) {
                paginationPages.push('t')
            }
            setpages(paginationPages)
            setpageCount(pagesCount)
            setresultBySerach(true)
            setStudentsData(res.data.results)
            setstudentCount(res.data.count)
            res.data.next ? setNextPage(true) : setNextPage(false)
        }).catch(err => {
            console.log(err)
        })
    }

    const deleteStudent = (id) => {
        Swal.fire({
            title: `هل انت متاكد من الحذف `,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: 'لا شكرا',
            showConfirmButton: true,
            confirmButtonText: 'حذف',
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                axios.delete(`${config.basURL}dashboard/student-details/${id}`, {
                    headers: {
                        'Authorization': config.ApiKey,
                        'auth': `Bearer ${userAuth}`
                    },
                    data: {
                        student_id: id
                    }
                }).then(res => {
                    if (resultBySerach) {
                        searchStudent()
                    } else {
                        getStudentsData()
                    }
                    Swal.fire({
                        text: 'تم الحذف بنجاح',
                        icon: 'success'
                    })
                }).catch(err => {
                    console.log(err)
                    Swal.fire({
                        text: ' حدث خطاء برجاء المحاوله مره اخري ',
                        icon: 'error'
                    })
                })
            }
        })
    }

    const exportCsv = () => {
        let csv = new CSVMake;
        csv.setTableHeader({
            name: 'أسم الطالب',
            code: 'الكود',
            user: ['username', ' رقم الطالب'],
            parent_phone: 'رقم ولي الامر',
            year: ['name', 'السنه الدراسية'],
            place: ['name', 'المكان'],
            type_education: ['name', 'نوع التعليم'],
        }).setData(studentsData, {
            user: 'username',
            year: 'name',
            place: 'name',
            type_education: 'name'
        }).exportFile(` بيانات الطلاب - صفحه رقم ( ${currentPage} ) `)
    }

    useEffect(() => {
        if (resultBySerach) {
            searchStudent()
        } else {
            getStudentsData()
        }
        getData()
    }, [currentPage, userAuth])

    return <>
        <div className="container-fluid dashboard-students-section">
            <div className="head">
                <h6 className='m-0 p-0'>
                   الطلاب المشتركين  { place_name } : ( { studentCount } )
                </h6>
                {
                    studentsData && <button className='export-file' onClick={exportCsv}>
                        <i className="fa-regular fa-file-excel"></i>
                        انشاء ملف Excel
                    </button>
                }
            </div>
            <form className="search-bar dashboard-student-search" onSubmit={searchStudent}>
                <select name="search_by" id="" onChange={updateSearchBy} >
                    <option value='all'> البحث باستخدام ... </option>
                    <option value='username'> رقم هاتف الطالب </option>
                    <option value='parent_phone'> رقم هاتف ولي الامر </option>
                    <option value='name'> اسم الطالب </option>
                    <option value='code'> كود الطالب </option>
                    <option value='place'> المكان </option>
                    <option value='year'> السنه الدراسية </option>
                    <option value='type_education'> نوع التعليم </option>
                </select>

                {
                    searchBy === 'year' ? <select name={searchBy} id="" onChange={updateSearchParam}>
                        <option > اختر السنه الدراسيه </option>
                        {
                            data && data.years.map(year => <option value={year.id} key={year.id}> {year.name} </option>)
                        }
                    </select> : false
                }
                {
                    searchBy === 'place' ? <select name={searchBy} id="" onChange={updateSearchParam}>
                        <option> اختر المكان </option>
                        {
                            data && data.places.map(place => <option value={place.id} key={place.id}> {place.name} </option>)
                        }
                    </select> : false
                }
                {
                    searchBy === 'type_education' ? <select name={searchBy} id="" onChange={updateSearchParam}>
                        <option> اختر نوع التعليم </option>
                        {
                            data && data.type_education.map(type => <option value={type.id} key={type.id}> {type.name} </option>)
                        }
                    </select> : false
                }
                {
                    ['year', 'place', 'type_education'].includes(searchBy) ? false : <input type="text" name={searchBy} placeholder=' ادخل البيانات ' onChange={updateSearchParam} ref={selectBox} />
                }
                <button> ابحث </button>
            </form>
            <table className='students-info student-table'>
                <thead>
                    <tr>
                        <th> التسلسل </th>
                        <th>
                            أسم الطالب
                        </th>
                        <th>
                            كود الطالب
                        </th>
                        <th>
                            رقم الطالب
                        </th>
                        <th>
                            رقم ولي الامر
                        </th>
                        <th>
                            السنه الدراسية
                        </th>
                        <th>
                            نوع التعليم
                        </th>
                        <th>
                            المكان
                        </th>
                        <th>
                            الايميل
                        </th>
                        <th>
                            نوع الجهاز المسجل بيه
                        </th>
                        <th>

                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        studentsData ? studentsData.length > 0 ? studentsData.map((student, index) => <tr key={index} >
                            <td className='image' onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                {/* <img src={avatar} alt="" className='w-100 h-100' /> */}
                                {index + 1}
                            </td>
                            <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                {student.name}
                            </td>
                            <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                {student.code}
                            </td>
                            <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                {student.user.username}
                            </td>
                            <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                {student.parent_phone}
                            </td>
                            <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                {student.year && student.year.name}
                            </td>
                            <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                {student.type_education && student.type_education.name}
                            </td>
                            <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                {student.place ? student.place.name : '--'}
                            </td>
                            <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                {student.user.email.length > 0 ? student.user.email : '--'}
                            </td>
                            <td onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                Phone
                            </td>
                            <td>
                                <button className='delete' onClick={() => deleteStudent(student.id)}>
                                    <i className="fa-regular fa-trash-can"></i>
                                </button>
                            </td>
                        </tr>) : <tr> <td colSpan='8' className='w-100 text-center'> لا يوجد طلاب </td> </tr> : <tr><td className='w-100' colSpan={9}> <Placeholder /> </td></tr>
                    }
                </tbody>
            </table>
            <div className="pagination-btn">
                <button onClick={() => setCurrentPage((prev) => prev - 1)} className={`prev ${currentPage > 1 ? '' : 'disable'}`} disabled={currentPage > 1 ? false : true}> السابق </button>
                <button className={`next ${nextPage ? '' : 'disable'}`} disabled={nextPage ? false : true} onClick={() => setCurrentPage((prev) => prev + 1)}> التالي </button>
            </div>
            <div className="paegination-links">
                <ul className='w-100 py-2'>
                    {
                        pages.map((pa, index) => <li className={`${currentPage == (index + 1) ? 'active' : ''}`} onClick={() => setCurrentPage(index + 1)}> {index + 1} </li>)
                    }
                </ul>
                <p className='text-center m-0 py-1'> {currentPage} / {pageCount} </p>
            </div>
        </div>
    </>
}