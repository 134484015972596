import React, { useContext, useEffect, useState } from 'react'
import Question from '../../Components/Quiz/Question'
import Counter from '../../Components/Used/Counter'
import Placeholder from '../../Components/Used/Placeholder'
import { json, useNavigate, useParams } from 'react-router-dom'
import config from '../../config'
import { auth } from '../../Context/AuthStore'
import axios from 'axios'
import Swal from 'sweetalert2'

export default function Quiz() {

  const navigate = useNavigate();
  const [currentTime, setCurrentTime] = useState(0)
  const { exam_id } = useParams()
  const [examData, setExamData] = useState(false)
  const [questionsData, setQuestionsData] = useState(false)
  const { userAuth } = useContext(auth)
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [studentAnswers, setStudentAnswers] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const nextQuestion = () => {
    let current = currentQuestion;
    current += 1;
    if (current < questionsData.length) {
      setCurrentQuestion(current)
    }
  }

  const chooseAnswer = (question_id, answer_id) => {
    let answers = { ...studentAnswers };
    answers.answers[question_id] = answer_id;
    setStudentAnswers(answers)
  }

  const getExamData = () => {
    axios.get(`${config.basURL}exam/get-exam/${exam_id}`, {
      headers: {
        'Authorization': config.ApiKey,
        'auth': `Bearer ${userAuth}`
      }
    }).then((res) => {
      if (res.data.exam) {
        let student = { answers: {} }
        setExamData(res.data.exam)
        setQuestionsData(res.data.questions)
        res.data.questions.map((question) => student['answers'][question.id] = '')
        setStudentAnswers(student)
      }else {
        Swal.fire({
          text: res.data,
          icon: 'warning',
          didClose: () => navigate(-1)
        })
      }

    }).catch((err) => {
      console.log(err)
    })
  }

  const sendStudentAnswers = (message, err) => {
    setIsLoading(true)
    let data = { ...studentAnswers }
    data.time_taken = currentTime
    axios.post(`${config.basURL}exam/get-exam/${exam_id}`, data, {
      headers: {
        'Authorization': config.ApiKey,
        'auth': `Bearer ${userAuth}`
      }
    }).then((res) => {
      // console.log(res.data)
      setIsLoading(false)
      Swal.fire({
        text: message,
        icon: err,
        didClose: () => navigate(`/user-profile/my-exam-score`)
      })
    }).catch((err) => {
      setIsLoading(false)
      Swal.fire({
        text: 'حدث خطاء برجاء الرجوع الي المشرف',
        icon: 'error',
        didClose: () => navigate(-1)
      })
      console.log(err)
    })
  }

  useEffect(() => {
    getExamData()
  }, [userAuth])

  // send answers time when user close tap or browser
  useEffect(() => {
    const onBeforeUnload = (ev) => {
      console.log('leave response')
      sendStudentAnswers();
      return null;
    };

    window.addEventListener("beforeunload", onBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [studentAnswers])

  // useEffect(() => {
  //   if(!window.navigator.onLine){
  //     Swal.fire({
  //       text: 'you are offline' ,
  //       icon: 'warning'
  //     })
  //   }
  // })

  return <>
    <div className='breadcrumb-exit-container'>
      <div className='container'>
        <ol className="breadcrumb">
          {/* <li className="breadcrumb-item">
            عنوان الكورس
          </li>
          <li className="breadcrumb-item">
            عنوان الدرس
          </li> */}
          <li className="breadcrumb-item" aria-current="page">
            السؤال رقم <span className='question-no'>{currentQuestion + 1}</span>
          </li>
        </ol>

        <button className='exit-quiz hidden-sm' onClick={() => sendStudentAnswers('تم ارسال الامتحان بنجاح', 'success')}>
          الخروج
        </button>
      </div>
    </div>
    {
      examData ? <div className='quiz-area-container py-5'>
        <div className='counter'>
          <i className="fa-solid fa-stopwatch"></i>
          {examData && <Counter time={examData.exam_minutes} end={sendStudentAnswers} takenTime={setCurrentTime} />}
        </div>
        <div className='quiz-area'>
          {
            questionsData && <Question data={questionsData[currentQuestion]} choose={chooseAnswer} answers={studentAnswers} />
          }
          {
            currentQuestion < (questionsData.length - 1) ? <button onClick={nextQuestion} className='next-question'>
              السؤال التالي
            </button> :
              <button className='submit' onClick={() => sendStudentAnswers('تم ارسال الامتحان بنجاح', 'success')} disabled={isLoading ? true : false}>
                {isLoading ? <i className="fa-solid fa-circle-notch fa-spin"></i> : <><i className="fa-regular fa-paper-plane"></i> ارسال</>}
              </button>
          }
        </div>
        <ul className='questions-pagination hidden-sm'>
          {
            questionsData && questionsData.map((q, index) => {
              return <li className={index === currentQuestion ? 'active' : ''} onClick={() => setCurrentQuestion(index)} > {index + 1} </li>
            })
          }
        </ul>
      </div> : <Placeholder />
    }
  </>
}
