import React from 'react'
import SectionContainer from '../../../Components/Dashboard/Used/SectionContainer'
import VideoRowBox from '../../../Components/Dashboard/VideoRowBox/VideoRowBox'

export default function Videos() {
    return <>
        <div className="container-fluid dashboard-videos-page">
            <h1>
                الفيديوهات
            </h1>
            <form className="new-video">
                <label className='video-upload top' htmlFor="video">
                    <input type="file" name="video" id="video" className='d-none'/>
                        <i className="fa-solid fa-arrow-up-from-bracket"></i>
                        تحميل ملف الفيديو
                </label>
                <div className='bottom'>
                    <input type="file" name='video-image' id='video-image' className='d-none ' />
                    <label htmlFor="video-image" className='video-image'>
                        <img src="" alt="" className='w-100 h-100' />
                        <p>
                            تحميل الصورة
                        </p>
                    </label>
                    <div className='video-name'>
                        <input type="text" name='video-name' className='video-name-input' placeholder='أسم الفيديو' />
                        <select name="course-name" id="">
                            <option value="">
                                أختر اسم الدرس
                            </option>
                        </select>
                        <input type="date" name="created-date" className='video-created-date' />
                        <button className='save-data'>
                            حفظ البيانات
                        </button>
                    </div>
                </div>
            </form>
            <SectionContainer sectionTitle={'أسم الوحدة  1'}>
                <VideoRowBox />
            </SectionContainer >
        </div>
    </>
}
