import axios from 'axios';
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import config from '../../../config';
import { auth } from '../../../Context/AuthStore';
import Swal from 'sweetalert2';

export default function UnitCourseBox({ data, refresh, courseid , openPopup }) {

    const { userAuth } = useContext(auth)

    function getHumanDate(d) {
        const date = new Date(d);
        const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('ar', options);
    }

    const deleteUnit = () => {
        Swal.fire({
            title: `هل انت متاكد من حذف الوحده : ${data.name}`,
            icon: "warning",
            showCancelButton : true ,
            cancelButtonText : 'لا شكرا' ,
            showConfirmButton : true,
            confirmButtonText : 'حذف',
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                axios.delete(`${config.basURL}dashboard/unit-overview/${courseid}`, {
                    headers: {
                        'Authorization': config.ApiKey,
                        'auth': `Bearer ${userAuth}`,
                    },
                    data: {
                        id_unit: data.id
                    }
                }).then((res) => {
                    refresh();
                    Swal.fire({
                        text: "تم الحذف بنجاح",
                        icon: "success",
                    });
                }).catch((err) => console.log(err))

            }
        })
    }

    return <>
        <div className="unit-course-box w-100">
            {/* <div className="image">
                <img src="" alt="" className='w-100 h-100' />
            </div> */}
            <p className='title'>
                {data.name}
            </p>
            <div className="lessons">
                <i className="fa-solid fa-quote-right"></i>
                <p>
                    {data.unit_lessons.length} دروس
                </p>
            </div>
            {/* <div className="videos">
                <i className="fa-solid fa-desktop"></i>
                <p className='m-0'>
                    4 فيديوهات
                </p>
            </div>
            <div className="quiz">
                <i className="fa-solid fa-paste"></i>
                <p className='m-0'>
                    4 امتحان
                </p>
            </div>
            <div className='homework'>
                <i className="fa-solid fa-book-open"></i>
                <p className='m-0'>
                    4 واجب
                </p>
            </div> */}
            <div className="date-setting">
                <div className='date'>
                    <p className='m-0'>
                        التاريخ
                    </p>
                    <p className='m-0'>
                        {getHumanDate(data.created)}
                    </p>
                </div>
                <div className="add-lessons">
                    <Link to={`/dashboard/lessons/${data.id}`}> اضف دروس </Link>
                </div>
                <div className="controll-btns">
                    <button className='setting' onClick={() => openPopup(data)}>
                        <i className="fa-solid fa-gear"></i>
                    </button>
                    <button className='delete' onClick={deleteUnit}>
                        <i className="fa-regular fa-trash-can"></i>
                    </button>
                </div>
            </div>
        </div>
    </>
}
