export default class Validator {
    constructor() {
        this.key = '';
        this.value = '';
        this.errors = {};
        this.haveError = false;
        this.translate = {
            name: 'الاسم',
            username: 'اسم المستخدم / رقم الهاتف',
            email: 'الايميل',
            password: 'كلمه المرور',
            password_confirmation: 'تاكيد كلمه المرور',
            lastName: 'الاسم الاخير',
            parent_phone: 'رقم ولي الامر',
            code: 'الكود',
            place: 'طريقه التسجيل',
            type_education: 'نوع التعليم',
            year: 'السنه الدراسيه',
            governorate: 'المحافظه',
            parent_phone_2: "رقم ولي الامر 2",
            division: 'الشعبه',
            whatsapp_phone: 'رقم واتساب'
        }
    }

    setKey(key) {
        this.key = key;
        return this;
    }

    setValue(value) {
        this.value = value;
        return this;
    }

    required() {
        if (this.value.length === 0) {
            this.errors[this.key] = []
            this.errors[this.key].push(`${this.translate[this.key]} : هذا الحقل مطلوب`);
            this.haveError = true
        }
        return this;
    }

    max(maximam) {
        if (this.value.length > maximam) {
            this.errors[this.key] = []
            this.errors[this.key].push(`${this.translate[this.key]} : هذا الحقل لا يجب ان يكون اكثر من ${maximam}  حرف`);
            this.haveError = true;
        }
        return this;
    }

    len(lenght) {
        if (this.value.length !== lenght) {
            this.errors[this.key] = []
            this.errors[this.key].push(`${this.translate[this.key]} : هذا الحقل يجب ان يكون ${lenght} رقم`);
            this.haveError = true;
        }
        return this;
    }

    notMatch(MatchValue) {
        if (this.value === MatchValue) {
            this.errors[this.key] = []
            this.errors[this.key].push(`${this.translate[this.key]} : هذا الحقل لا يجب ان يتطابق مع ${MatchValue}`);
            this.haveError = true;
        }
        return this;
    }

    confirm(MatchValue) {
        if (this.value != MatchValue) {
            this.errors[this.key] = []
            this.errors[this.key].push(`${this.translate[this.key]} : هذا الحقل يجب ان يتطابق مع ${MatchValue}`);
            this.haveError = true;
        }
        return this;
    }

    is_arabic() {
        let arabic = /[\u0600-\u06FF]/;
        if (!arabic.test(this.value)) {
            this.errors[this.key] = []
            this.errors[this.key].push(` ${this.translate[this.key]} :  يجب الكتابه باللغه العربيه في هذا الحقل`);
            this.haveError = true;
        }
        return this;
    }

    words_count(count) {
        if (this.value.split(' ').length < count) {
            this.errors[this.key] = []
            this.errors[this.key].push(` ${this.translate[this.key]}  : هذا الحقل يجب ان يتكون من ${count} كلمه`);
            this.haveError = true;
        }
        return this;
    }

    number() {
        let reg = /^\d+$/
        if (!reg.test(this.value)) {
            this.errors[this.key] = []
            this.errors[this.key].push(` ${this.translate[this.key]} : يجب كتابه ارقام فقط في هذا الحقل `);
            this.haveError = true;
        }
        return this;
    }

    addError() {
        this.errors[this.key] = []
        this.errors[this.key].push(`${this.translate[this.key]} : هذا الحقل مطلوب`);
        this.haveError = true
    }

    getErrors() {
        return this.errors;
    }

}