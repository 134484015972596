import React, { useRef } from 'react'

export default function Question(props) {

    const answers = useRef()

    const selectAnswer = (e) => {
        // remove active class from all elements
        let elements = answers.current.children;
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove("active");
        }
        // add active class to selected element
        e.target.classList.add("active")
    }

    return <>
        <div className='question'>
            <div className='question-title-container'>
                <h5 className='question-title' dangerouslySetInnerHTML={{'__html' : props.data.text}} />
            </div>
            <ul className='answers' ref={answers}>
                {
                    props.data.answer_set && props.data.answer_set.map(answer => <li dangerouslySetInnerHTML={{'__html' : answer.text}} onClick={() => {props.choose(props.data.id , answer.id)}} key={answer.id} data-id={answer.id} className={`answer ${props.answers.answers[props.data.id] && (props.answers.answers[props.data.id] === answer.id ? 'active' : '')}`}>
                    </li>)
                }

            </ul>
        </div>
    </>
}
