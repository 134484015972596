import React from "react";
import pdf from "../Components/imges/center.png";

export default function CenterCalender() {
  return (
    <>
      <div className="center-calender">
        <div className="section-header-content">
          <h5>مواعيد السنتر</h5>
        </div>
        <div className="container calender py-3">
          <img src={pdf} alt="" className="w-100" />
        </div>
      </div>
    </>
  );
}
