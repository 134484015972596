import React from "react";
import "../header/Header.css";
import AOS from "aos";
import "aos/dist/aos.css";
import back from "../../../../assets/italic/1.png";
import first from "../../../../assets/italic/2.png";
import second from "../../../../assets/italic/3.png";
import third from "../../../../assets/italic/4.png";
export const Header = () => {
  AOS.init({
    offset: 200,
    duration: 600,
    easing: "ease-in-sine",
    delay: 100,
  });
  return (
    <>
      <section className="home">
        <div className="titles">
          <h1
            data-aos="fade-down"
            data-aos-duration="1400"
            data-aos-mirror="true"
            data-aos-once="false"
            className="title text-center mt-4 "
          >
            Signora Inas Saad
          </h1>
          <p
            data-aos="fade-down"
            data-aos-duration="1400"
            data-aos-mirror="true"
            data-aos-once="false"
          >
            {" "}
            فى اللغة الايطالية
          </p>
        </div>
        <img
          data-aos="fade-left"
          data-aos-duration="1900"
          data-aos-mirror="true"
          data-aos-once="false"
          className="back"
          srcSet={back}
          alt=""
        />
        <div className="home-up">
          <div
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-mirror="true"
            data-aos-once="false"
            className="home-up-right"
          >
            <img srcSet={first} alt="" />
          </div>
          <div
            data-aos="fade-right"
            data-aos-duration="1600"
            className="home-up-left"
          >
            <img srcSet={second} alt="" />
          </div>
        </div>
        <div data-aos="fade-up" data-aos-duration="2000" className="home-down">
          <img srcSet={third} alt="" />
        </div>
      </section>
    </>
  );
};
