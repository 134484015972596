import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import CourseUniteBox from "../Components/UserCourses/CourseUniteBox";
import axios from "axios";
import config from "../config";
import { auth } from "../Context/AuthStore";
import Swal from "sweetalert2";

export default function WatchLessonByCode() {
  const { code } = useParams();
  const { userAuth } = useContext(auth);
  const player = useRef();
  const iframe = useRef();
  const [videoData, setvideoData] = useState(false);
  const [lessonData, setlessonData] = useState(false);
  const [exams, setExams] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const [avilabile, setAvilabile] = useState(true);
  const [watchTime, setWatchTime] = useState(0);
  const [intervalID, setIntervalID] = useState(null);
  const [lessonNumber, setLessonNumber] = useState(0);
  const navigate = useNavigate();

  const getLessonData = () => {
    axios
      .get(`${config.basURL}codes/view-lesson-by-code/${code}`, {
        headers: {
          Authorization: config.ApiKey,
        },
      })
      .then((res) => {
        if (res.data.view_counter === 0 && res.data.view_counter !== undefined) {
          Swal.fire({
            text: "انتهت مشاهداتك للدرس باستخدام هذا الكود",
            icon: "error",
            willClose: () => navigate("/lesson-by-code"),
          });
        }
        setvideoData(res.data.credentials);
        setlessonData(res.data.lesson.lesson);
        setExams(res.data.lesson.lesson.exam_set);
      })
      .catch((err) => console.log(err));
  };

  const sendWatchTry = () => {
    axios
      .post(
        `${config.basURL}codes/view-lesson-by-code/${code}`,
        {},
        {
          headers: {
            Authorization: config.ApiKey,
          },
        }
      )
      .then((res) => {
        setFirstTime(false);
      })
      .catch((err) => {
        console.log(err);
        setAvilabile(false);
        clearInterval(intervalID);
      });
  };

  // const sendWatchTime = () => {
  //     axios.post(`${config.basURL}subscription/lesson-views`, {
  //         watch_time: watchTime / 60,
  //         lesson: lesson,
  //         save_time: true
  //     },
  //         {
  //             headers: {
  //                 'Authorization': config.ApiKey,
  //                 'auth': `Bearer ${userAuth}`
  //             }
  //         }).then((res) => { }).catch((err) => console.log(err))
  // }

  useEffect(() => {
    player.current = window.VdoPlayer.getInstance(iframe.current);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    getLessonData();
  }, []);

  // record user watch time
  useEffect(() => {
    const playHandler = () => {
      // check if user play video at first time to send request
      if (firstTime) {
        sendWatchTry();
      }
      const id = setInterval(() => {
        setWatchTime((prevTime) => prevTime + 1);
      }, 1000);
      setIntervalID(id);
    };

    // add send watch when click play
    player.current.video.addEventListener("playing", playHandler);

    return () => {
      player.current.video.removeEventListener("playing", playHandler);
    };
  }, [firstTime, watchTime]);

  // pause record user watch time
  useEffect(() => {
    const pauseHandler = () => {
      clearInterval(intervalID);
      // sendWatchTime()
    };

    player.current.video.addEventListener("pause", pauseHandler);

    return () => {
      // removing event listener
      player.current.video.removeEventListener("pause", pauseHandler);
    };
  }, [intervalID, watchTime]);

  // handle when video is end
  useEffect(() => {
    const handleEndedVideo = () => {
      // sendWatchTime();
      clearTimeout(intervalID);
    };
    player.current.video.addEventListener("ended", handleEndedVideo);
    return () => {
      player.current.video.removeEventListener("ended", handleEndedVideo);
    };
  }, [watchTime]);

  // stop counter if video not avilabile
  useEffect(() => {
    if (!avilabile) {
      clearInterval(intervalID);
    }
  }, [avilabile]);

  // send watch time when user close tap or browser
  // useEffect(() => {

  //     const onBeforeUnload = (ev) => {
  //         sendWatchTime();
  //         return null;
  //     };

  //     window.addEventListener("beforeunload", onBeforeUnload);

  //     return () => {
  //         window.removeEventListener("beforeunload", onBeforeUnload);
  //     };
  // }, [watchTime])

  return (
    <>
      <div className="container my-course-watch-page">
        {avilabile ? (
          <iframe
            ref={iframe}
            src={`https://player.vdocipher.com/v2/?otp=${
              videoData && videoData.otp
            }&playbackInfo=${videoData && videoData.playbackInfo}`}
            style={{ border: 0 }}
            allow="encrypted-media"
            allowFullScreen
            id="frame"
          ></iframe>
        ) : (
          <div className="alert alert-info text-center">
            انتهت مشاهداتك للدرس
          </div>
        )}

        <div className="lesson-info">
          <div className="lesson-title">
            <h5>{lessonData && lessonData.name}</h5>
            <div className="current-lesson">
              {/* الدرس {lessonNumber && lessonNumber}/{units && units[0].unit_lessons.length} */}
            </div>
          </div>
          <div className="lesson-description">
            <p className="description">
              شرح تفصيلي لالمحتوا الداخلي للكورس. شرح تفصيلي شرح تفصيلي لالمحتوا
              الداخلي للكورس. شرح تفصيلي لالمحتوا الداخلي للكورس.
            </p>
            <div className="video-duration"></div>
          </div>
        </div>
        {/* to lesson quiz section */}

        {exams &&
          exams.map((exam) => (
            <div className="quiz-section">
              <div className="start-quiz">
                <h6>{exam.name}</h6>
                <p>{exam.description}</p>
                <Link to={`/quiz/${lessonData && lessonData.id}/${exam.id}`}>
                  أبداء
                </Link>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}
