import React, { useContext, useEffect, useState } from 'react'
import dashboardGradImage from "../../../assets/dashboard-grad.svg"
import avatar from "../../../assets/avatar.svg"
import StudentRow from '../../../Components/Dashboard/Home/StudentRow'
import bookImage from '../../../assets/book.svg'
import CircularProgress from '../../../Components/CircularProgress'
import axios from 'axios'
import config from '../../../config'
import { auth } from '../../../Context/AuthStore'

export default function DashboardHome() {

    const { userAuth } = useContext(auth)
    const [totlaStudents, setTotlaStudents] = useState(0)
    const [lastStudents, setLastStudents] = useState(false)
    const [yearPercentage, setYearPercentage] = useState(false)

    const getOverview = () => {
        axios.get(`${config.basURL}dashboard/overview`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then((res) => {
            setTotlaStudents(res.data.total_student_count)
            setLastStudents(res.data.student_serializer)
            setYearPercentage(res.data.year_percentage)
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        getOverview()
    }, [userAuth])

    return <>
        <div className="container-fluid dashboard-home-section">
            <div className="welcome-box">
                <h3 className='title'>
                أهلا بيك 
                    <span className='admin-name'>
                         
                    </span>
                </h3>
                <div className="new-subscriptions">
                    <h5 className='fw-bold'>
                        المشتركين
                        الجدد
                    </h5>
                    <div className='image'>
                        <img src={dashboardGradImage} alt="" className='w-100 h-100' />
                    </div>
                    <div className="avatars-container">
                        <p>{totlaStudents}</p>
                        <ul className="avatars">
                            <li>
                                <img src={avatar} alt="" />
                            </li>
                            <li>
                                <img src={avatar} alt="" />
                            </li>
                            <li>
                                <img src={avatar} alt="" />
                            </li>
                            <li>
                                <img src={avatar} alt="" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="analysis">
                <div className='right students'>
                    <h6 className='title'>
                        الطلاب
                    </h6>
                    <div className="content scrolled-y">
                        {
                            lastStudents && lastStudents.map((student, index) => <StudentRow key={index} data={student} />)
                        }

                    </div>
                </div>
                <div className="left">
                    {/* <div className="top monthly-subscription-count">
                        <h6 className='title'>
                            عدد المشتركين الشهري
                        </h6>
                        <div className="chart"></div>
                    </div> */}
                    <div className="bottom passed-in-courses">
                        <h6 className='title'>
                            نسبة الناجحين في الكورسات
                        </h6>
                        <div>
                            <div className="image">
                                <img src={bookImage} alt="" className='w-100 h-100' />
                            </div>
                            <div className='courses-pass-progress'>
                                <ul>
                                    <li>
                                        {yearPercentage && <CircularProgress value={Math.floor(yearPercentage.total_in_year_1)} max={100} color={'#7910E9'} />}

                                        <p>
                                            الاول
                                            الثانوي
                                        </p>
                                    </li>
                                    <li>
                                        {yearPercentage && <CircularProgress value={Math.floor(yearPercentage.total_in_year_2)} max={100} color={'#7910E9'} />}
                                        <p>
                                            االثاني
                                            الثانوي
                                        </p>
                                    </li>
                                    <li>
                                        {yearPercentage && <CircularProgress value={Math.floor(yearPercentage.total_in_year_3)} max={100} color={'#7910E9'} />}
                                        <p>
                                            االثالث
                                            الثانوي
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

