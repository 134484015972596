class CSVMake {
  constructor() {
    this.tableHeader = [];
    this.displayName = "";
    this.data = [];
  }

  // take object have key same as in data and value is the display name in sheet
  setTableHeader(tableHeaderData) {
    let displayNames = [];
    for (const key in tableHeaderData) {
      this.tableHeader.push(key);
      if (typeof tableHeaderData[key] === "object") {
        displayNames.push(tableHeaderData[key][1]);
      } else {
        displayNames.push(tableHeaderData[key]);
      }
    }

    this.displayName = displayNames.join(",");
    return this;
  }

  setData(allData, instractions = null) {
    let rows = [];

    if (instractions === null) {
      allData.forEach((data) => {
        let row = [];
        for (let i = 0; i < this.tableHeader.length; i++) {
          row.push(data[this.tableHeader[i]]);
        }
        rows.push(row.join(","));
      });
    } else {
      allData.forEach((data) => {
        let row = [];
        for (let i = 0; i < this.tableHeader.length; i++) {
          if (instractions[this.tableHeader[i]]) {
            try {
              row.push(
                data[this.tableHeader[i]][instractions[this.tableHeader[i]]]
              );
            } catch (err) {
              row.push("لا يوجد");
            }
          } else {
            row.push(data[this.tableHeader[i]]);
          }
        }
        rows.push(row.join(","));
      });
    }
    this.data = [...rows];
    return this;
  }

  exportFile(fileName) {
    let allRows = [this.displayName, ...this.data].join("\n");
    const universalBom = "\uFEFF";
    const url = `data:text/csv;charset=utf-8,${encodeURIComponent(
      universalBom + allRows
    )}`;
    const link = document.createElement("a");
    link.download = ` ${fileName} - ${this.getHumanDate()}.csv`;
    link.href = url;
    link.click();
  }

  getHumanDate() {
    const date = new Date();
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return date.toLocaleDateString("ar", options);
  }
}
export default CSVMake;
