import React, { useContext, useEffect, useState } from "react";
import { auth } from "./../Context/AuthStore";
import { Navigate } from "react-router-dom";
import Placeholder from "../Components/Used/Placeholder";

export default function AuthGourd(props) {
  let { userAuth } = useContext(auth);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
    return () => false;
  }, []);
  return (
    <>
      {loading ? (
        <table className="loading-gurad">
          <tbody className="loading-gurad">
            <Placeholder />
          </tbody>
        </table>
      ) : userAuth ? (
        props.children
      ) : (
        <Navigate to={"/auth"} />
      )}
    </>
  );
}
