import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useOutletContext } from 'react-router-dom'
import config from '../../config'
import axios from 'axios'
import { auth } from '../../Context/AuthStore'
import Swal from 'sweetalert2'

export default function CourseBox({ data }) {

    const navigate = useNavigate()

    const { userAuth } = useContext(auth);
    const { showSwal } = useOutletContext();

    const [lessonsCount, setlessonsCount] = useState(0)
    const [openPopup, setopenPopup] = useState(false)
    const [codeData, setcodeData] = useState({
        code: ''
    })

    const [isActive, setisActive] = useState(false)

    const subscripe = () => {

        if (userAuth === null) {
            showSwal('لا يمكن الاشتراك في الكورس بدون تسجيل الدخول', 'error', '/auth')
        } else {
            axios.post(`${config.basURL}subscription/register-course`, {
                course: data.id
            }, {
                headers: {
                    'Authorization': config.ApiKey,
                    'auth': `Bearer ${userAuth}`,
                }
            }).then((res) => {
                console.log(res.data)
                if (res.data.created) {
                    res.data.data.course.free ?
                        showSwal('تم الاشتراك في الكورس بنجاح', 'success', '/user-profile/my-courses') :
                        showSwal('تم الاشتراك في الكورس بنجاح', 'success', '/user-profile/course-subscreptions')
                } else {
                    showSwal('لقد قمت بالاشتراك في هذا الكورس من قبل', 'info')
                }

            }).catch((err) => {
                console.log(err)
            })
        }
    }

    const showPopup = () => {
        setopenPopup(true)
    }

    const hidePopup = (e) => {
        if (e.target.classList.contains('active-course-code-overlay')) {
            setopenPopup(false)
        }
    }

    const updateCodeData = (e) => {
        let data = { ...codeData }
        data[e.target.name] = e.target.value;
        setcodeData(data)
    }

    const activeCourse = (e) => {
        e.preventDefault();
        setisActive(true)

        if (codeData.code == '') {
            Swal.fire({
                text: ' اكتب كود التفعيل ',
                icon: 'error'
            })
            setisActive(false)
            return;
        }

        axios.post(`${config.basURL}codes/active-course-by-code`, {
            code: codeData.code
        }, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            Swal.fire({
                text: ' تم تفعيل الكورس بنجاح  ',
                icon: 'success'
            })
            setisActive(false)
            navigate('/user-profile/my-courses')
        }).catch(err => {
            setisActive(false)
            if (err.response.status == 404) {
                Swal.fire({
                    text: ' هذا الكود غير صحيح ',
                    icon: 'error'
                })
            } else if (err.response.status == 406) {
                Swal.fire({
                    text: ' هذا الكود تم استخدامه من قبل ',
                    icon: 'error'
                })
            } else {
                Swal.fire({
                    text: ' حدث خطاء ',
                    icon: 'error'
                })
            }
        })
    }


    function getHumanDate(d) {
        const date = new Date(d);
        const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('ar', options);
    }

    useEffect(() => {
        setlessonsCount(0)
        data.course_units.map(unit => setlessonsCount((prev) => prev += unit.unit_lessons.length))
        // console.log(data)
    }, [])

    return <>

        <div className='course-box'>
            <div className='course-image'>
                <img src={`${data.cover}`} className='w-100 h-100' loading='lazy' />
                {/* <div className='add-course-icon'>
                    <i className="fa-solid fa-plus"></i>
                </div> */}
            </div>
            <div className='lessons-count'>
                <p><i className="ms-1 fa-solid fa-book"></i> {lessonsCount} الدروس</p>
                <p><i className="ms-1 fa-solid fa-share-nodes"></i> {data.course_units.length} الوحدات</p>
                <p><i className="ms-1 fa-regular fa-file"></i> {data.course_file.length} الفايلات</p>
            </div>
            <div className='course-title'>
                <h5 className='title fw-bold'>
                    {data.name}
                </h5>
                {/* <p className='p-0 m-0'>
                    {data.about}
                </p> */}
            </div>
            <div className='course-time-price'>
                <div className='course-time'>
                    <div className='time'>
                        <i className="fa-regular fa-clock"></i>
                        <p className='m-0 p-0'>
                            {
                                getHumanDate(data.created)
                            }
                        </p>
                    </div>
                </div>
                <div className='price'>
                    {
                        data.free ? 'مجاني' : <><span>{data.price}</span>جـ</>
                    }
                </div>
            </div>
            <div className='go-to-course'>
                <Link to={`/course-preview/${data.id}`} className='enter'>
                    الدخول للكورس
                </Link>
                <button onClick={subscripe} className='enroll'>
                    أشترك الأن
                </button>
            </div>
            <div>
                <button className='active-course-by-code' onClick={showPopup}> تفعيل الكورس باسخدام الكود  </button>
            </div>
        </div>

        <div className={`active-course-code-overlay align-items-center justify-content-center ${openPopup && 'show'}`} onClick={hidePopup}>
            <div>
                <form onSubmit={activeCourse}>
                    <h1 className='py-3'> قم بكتابه كود تفعيل الكورس </h1>
                    <input name='code' onChange={updateCodeData} class="form-control form-control-lg" type="text" placeholder=" كود التفعيل " aria-label=".form-control-lg example" />
                    <div>
                        <button> {
                            isActive ? <i className="fa-solid fa-circle-notch"></i> : 'فعل الكورس '
                        } </button>
                    </div>
                </form>
            </div>
        </div>
    </>
}
