import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import useQueryParams from "./../../Hooks/useQueryParams";
import { Link, useParams } from "react-router-dom";
import CourseUniteBox from "../../Components/UserCourses/CourseUniteBox";
import axios from "axios";
import config from "../../config";
import { auth } from "../../Context/AuthStore";
import Videocipher from "../../Components/Used/Videocipher";
import Video from "../../Components/Used/Video";
import Placeholder from "../../Components/Used/Placeholder";

export default function UserWatchCourse() {
  let { unit, lesson } = useQueryParams();
  const { course_id } = useParams();
  const { userAuth } = useContext(auth);
  const player = useRef();
  const iframe = useRef();
  const [units, setUnits] = useState(false);
  const [videoData, setvideoData] = useState(false);
  const [lessonData, setlessonData] = useState(false);
  const [exams, setExams] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const [avilabile, setAvilabile] = useState(true);
  const [watchTime, setWatchTime] = useState(0);
  const [intervalID, setIntervalID] = useState(null);
  const [lessonNumber, setLessonNumber] = useState(0);

  const getUnits = () => {
    axios
      .get(`${config.basURL}course/course-units/${course_id}`, {
        headers: {
          Authorization: config.ApiKey,
        },
      })
      .then((res) => {
        let u = res.data.filter((unitData) => unitData.id === Number(unit));
        let lessonNumber;
        for (let i = 0; i < u[0].unit_lessons.length; i++) {
          if (u[0].unit_lessons[i].id == Number(lesson)) {
            setLessonNumber(i + 1);
            break;
          }
        }
        setUnits(u);
      })
      .catch((err) => console.log(err));
  };

  const getLessonData = () => {
    axios
      .get(
        `${config.basURL}subscription/access-lesson/${course_id}/${lesson}`,
        {
          headers: {
            Authorization: config.ApiKey,
            auth: `Bearer ${userAuth}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data)
        setvideoData(res.data.credentials);
        setlessonData(res.data.lesson_data);
        setExams(res.data.lesson_data.exam_set);
      })
      .catch((err) => console.log(err));
  };

  const sendWatchTry = () => {
    if (firstTime == false) {
      return;
    }

    axios
      .post(
        `${config.basURL}subscription/lesson-views`,
        {
          watch_time: 0,
          lesson: lesson,
        },
        {
          headers: {
            Authorization: config.ApiKey,
            auth: `Bearer ${userAuth}`,
          },
        }
      )
      .then((res) => {
        setFirstTime(false);
        console.log("watch try ");
      })
      .catch((err) => {
        // console.log(err)
        setAvilabile(false);
        clearInterval(intervalID);
      });
  };

  const sendWatchTime = () => {
    console.log(watchTime / 60 )
    axios
      .put(
        `${config.basURL}subscription/lesson-views`,
        {
          watch_time: watchTime / 60,
          lesson: lesson,
        },
        {
          headers: {
            Authorization: config.ApiKey,
            auth: `Bearer ${userAuth}`,
          },
        }
      )
      .then((res) => {})
      .catch((err) => {
        // console.log(err)
      });
  };

  useEffect(() => {
    // player.current = window.VdoPlayer.getInstance(iframe.current);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    getUnits();
    getLessonData();
  }, [lesson]);

  // send watch time when user close tap or browser
  useEffect(() => {
    const onBeforeUnload = (ev) => {
      sendWatchTime();
      return null;
    };

    window.addEventListener("beforeunload", onBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [watchTime]);

  return (
    <>
      <div className="container my-course-watch-page">
        {lessonData &&
          (lessonData.cloud_server !== null ? (
            <Video
              videoLink={lessonData.cloud_server}
              firstTime={firstTime}
              sendWatchTry={sendWatchTry}
              setIntervalID={setIntervalID}
              setWatchTime={setWatchTime}
              intervalID={intervalID}
              sendWatchTime={sendWatchTime}
              avilabile={avilabile}
              watchTime={watchTime}
            />
          ) : (
            <Videocipher
              avilabile={avilabile}
              videoData={videoData}
              firstTime={firstTime}
              watchTime={watchTime}
              intervalID={intervalID}
              sendWatchTime={sendWatchTime}
              setWatchTime={setWatchTime}
              setIntervalID={setIntervalID}
              sendWatchTry={sendWatchTry}
            />
          ))}

        <div className="lesson-info">
          <div className="lesson-title">
            <h5>{lessonData && lessonData.name}</h5>
            <div className="current-lesson">
              الدرس {lessonNumber && lessonNumber}/
              {units && units[0].unit_lessons.length}
            </div>
          </div>
          <div className="lesson-description">
            <p className="description">
              {lessonData && lessonData.description}
            </p>
            <div className="video-duration"></div>
          </div>
        </div>
        {/* to lesson quiz section */}

        {exams &&
          exams.map((exam) => (
            <div className="quiz-section">
              <div className="start-quiz">
                <h6>{exam.name}</h6>
                <p>{exam.description}</p>
                <Link to={`/quiz/${course_id}/${exam.id}`}>أبداء</Link>
              </div>
            </div>
          ))}

        <div className="course-files mb-5 py-3">
          {lessonData ? (
            lessonData.lesson_file.length > 0 ? (
              lessonData.lesson_file.map((file) => (
                <a href={file.file} className="file d-block" target="_blank">
                  <div className="icon">
                    <i className="fa-solid fa-file"></i>
                  </div>
                  <p className="m-0">
                    {decodeURIComponent(
                      file.file.split("/")[file.file.split("/").length - 1]
                    )}
                  </p>
                </a>
              ))
            ) : (
              <p className="w-100 m-0 py-2 text-center">
                {" "}
                لا يوجد فايلات في هذا الدرس{" "}
              </p>
            )
          ) : (
            <Placeholder />
          )}
        </div>

        {/* other lessons */}
        <h6 className="text-center mt-5 mb-5">الانتقال لباقي الدروس</h6>
        <div className="chapters-container row gap-5 pb-5">
          {units &&
            units.map((unit) => <CourseUniteBox key={unit.id} data={unit} />)}
        </div>
      </div>
    </>
  );
}
