import React, { useContext, useState } from "react";
import UserLoginIcon from "../../assets/auth/userLoginIcon.svg";
import googleIconLogo from "../../assets/auth/google-icon-logo 1.svg";
import { Link, useOutletContext } from "react-router-dom";
import { auth } from "../../Context/AuthStore";
import config from "../../config";
import axios from "axios";
import Validator from "../../Validator";
import Swal from "sweetalert2";

export default function Login() {
  const { setHaveError, showSwal, createPopupErrors } = useOutletContext();
  const { login, setUserAuth } = useContext(auth);
  const [byCode, setByCode] = useState(false);

  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
  });

  let [errors, setErrors] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const updateInput = (e) => {
    let newLoginData = { ...loginData };
    newLoginData[e.target.name] = e.target.value;
    setLoginData(newLoginData);
  };

  let clearInputData = (inputName) => {
    let data = { ...loginData };
    data[inputName] = "";
    setLoginData(data);
  };

  const loginValidation = () => {
    const validate = new Validator();
    validate.setKey("username").setValue(loginData.username).required();
    validate.setKey("password").setValue(loginData.password).required();
    return validate;
  };

  const sendData = () => {
    let data = loginData;

    // check if by code or not
    if (byCode) {
      let valid = new Validator();
      valid
        .setKey("code")
        .setValue(loginData.username)
        .required()
        .number();
      if (valid.haveError) {
        console.log(valid.getErrors())
        Swal.fire({
          text: "الكود غير صحيح",
          icon: "error",
        });
        setIsLoading(false);
        return;
      } else {
        data.password = data.username;
      }
    }

    axios
      .post(`${config.basURL}student/student-sign-in`, data, {
        headers: {
          Authorization: config.ApiKey,
        },
      })
      .then((res) => {
        // add user token to context api
        setUserAuth(res.data.access);
        // add token to localstorage
        localStorage.setItem("userToken", res.data.access);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setHaveError(true);
        createPopupErrors({
          username: ["اسم المستخدم او كلمه المرور غير صحيح"],
        });
        showSwal();
      });
  };

  const submitLogin = (e) => {
    e.preventDefault();
    let validation = loginValidation();

    if (!byCode) {
      if (validation.haveError) {
        createPopupErrors(validation.getErrors());
        setHaveError(true);
        showSwal();
        return;
      }
    }

    // reset errors
    setErrors([]);
    setIsLoading(true);
    sendData();
  };

  const openByCode = () => {
    byCode ? setByCode(false) : setByCode(true);
  };

  return (
    <>
      <div className="login-section p-4">
        <div className="icon-title">
          <div className="icon">
            <img src={UserLoginIcon} className="w-100 h-100" />
          </div>
          <h5 className="text-center">تسجيل الدخول</h5>
        </div>
        <form className="login-form py-2" onSubmit={submitLogin}>
          {byCode ? (
            <>
              <div className="user-name">
                <input
                  onChange={updateInput}
                  name="username"
                  type="text"
                  placeholder="ادخل الكود"
                  value={loginData.username ? loginData.username : ""}
                />
                <div
                  className={`clear-input ${
                    loginData.username ? "" : "d-none"
                  }`}
                  onClick={() => clearInputData("username")}
                >
                  <i className="fa-solid fa-circle-xmark"></i>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="user-name">
                <input
                  onChange={updateInput}
                  name="username"
                  type="text"
                  placeholder="رقم الهاتف"
                  value={loginData.username ? loginData.username : ""}
                />
                <div
                  className={`clear-input ${
                    loginData.username ? "" : "d-none"
                  }`}
                  onClick={() => clearInputData("username")}
                >
                  <i className="fa-solid fa-circle-xmark"></i>
                </div>
              </div>
              {/* <Link className='forget-password w-100 d-block' to={'/forget-password'}>
                نسيت كلمة المرور ؟
              </Link> */}
              <div className="user-password">
                <input
                  onChange={updateInput}
                  name="password"
                  type="password"
                  placeholder="كلمة السر"
                  value={loginData.password ? loginData.password : ""}
                />
                <div
                  className={`clear-input ${
                    loginData.password ? "" : "d-none"
                  }`}
                  onClick={() => clearInputData("password")}
                >
                  <i className="fa-solid fa-circle-xmark"></i>
                </div>
              </div>
            </>
          )}

          <button type="submit" className="login-btn" disabled={isLoading}>
            {isLoading ? (
              <i className="fa-solid fa-circle-notch fa-spin"></i>
            ) : (
              "تسجيل الدخول"
            )}
          </button>
          <div className="google-login" onClick={openByCode}>
            {byCode ? "تسجيل الدخول برقم الهاتف" : " تسجيل عن طريق الكود"}
          </div>
        </form>
        <div className="register pt-4">
          <Link to={"register"}>انشاء حساب جديد</Link>
          ليس لديك حساب -
        </div>
      </div>
    </>
  );
}
