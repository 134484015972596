import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import config from '../../../config'
import { auth } from '../../../Context/AuthStore'
import Placeholder from '../../../Components/Used/Placeholder'
import CSVMake from '../../../CSVMake'

export default function PrevCopons() {

    const [allCoponsCount, setAllCoponsCount] = useState(0)
    const [allStudentsCode, setAllStudentsCode] = useState(false)
    const { userAuth } = useContext(auth)
    const [currentPage, setCurrentPage] = useState(1)
    const [nextPage, setNextPage] = useState(false)
    const [displayCodes, setDisplayCodes] = useState({
        display: 'lessons'
    })

    const updateDisplay = (e) => {
        let data = { ...displayCodes }
        data.display = e.target.value;
        setDisplayCodes(data)
        setCurrentPage(1)
    }

    const getAllCopons = () => {
        setAllStudentsCode(false)
        axios.get(`${config.basURL}dashboard/all-codes-${displayCodes.display}?page=${currentPage}`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            console.log(res.data)
            setAllCoponsCount(res.data.count)
            if (displayCodes.display === "lessons") {
                setAllStudentsCode(res.data.results)
            } else {
                setAllStudentsCode(res.data.results)
            }
            res.data ? setNextPage(true) : setNextPage(false);
        }).catch(err => {
            console.log(err)
        })
    }

    function getHumanDate(d) {
        const date = new Date(d);
        const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('ar', options);
    }

    const exportCsv = () => {
        let csv = new CSVMake;
        csv.setTableHeader({
            code: 'الكود',
            lesson: ['name', "اسم الدرس"],
            view_counter: 'عدد مرات المشاهده'
        }).setData(allStudentsCode, {
            lesson: 'name'
        }).exportFile('اكواد الدروس')
    }

    const exportStudentCsv = () => {
        let csv = new CSVMake;
        csv.setTableHeader({
            code: 'الكود',
            type_education: ['name', "نوع التعليم"],
            place: ['name', 'المكان'],
            year: ['name', 'السنه الدراسية']
        }).setData(allStudentsCode, {
            type_education: 'name',
            place: 'name',
            year: 'name'
        }).exportFile('اكواد الطلاب')
    }

    useEffect(() => {
        getAllCopons()
    }, [userAuth, currentPage, displayCodes])

    return <>
        <div className="container-fluid dashboard-prev-copons">
            <div className="page-header">
                <h1>
                    الأكواد السابقة ( {allCoponsCount} )
                </h1>
                <Link to={'/dashboard/create-copon'} className='create-copon'>
                    انشاء كود
                </Link>
            </div>
            <div className="filter">
                <label htmlFor="code-type" className='m-2'> اختر نوع الاكواد </label>
                <select name="display" id="code-type" onChange={updateDisplay}>
                    <option value="lessons"> اكواد الدروس </option>
                    <option value="students"> اكواد الطلاب </option>
                </select>
            </div>
            {
                allStudentsCode && <div className="section-header">
                    <button className='export-file' onClick={() => displayCodes.display === 'lessons' ? exportCsv() : exportStudentCsv()}>
                        <i className="fa-regular fa-file-excel"></i>
                        انشاء ملف Excel {displayCodes.display === 'lessons' ? 'اكواد الدروس' : "اكواد الطلاب"}
                    </button>
                </div>
            }

            <div className='copons'>
                {/* {
                    allCopons ? (allCopons.length > 0 ? allCopons.map(code => <div className='copon-row'>
                        <div className="right">
                            <p className="code m-0">
                                {code.code}
                            </p>
                            <p className="m-0 me-2">
                                عدد مرات المشاهده : <span className={`badge ${code.view_counter > 0 ? 'bg-primary' : 'bg-danger'}`} >{code.view_counter}</span>
                            </p>
                            <p className="m-0">
                                {code.lesson.name}
                            </p>
                        </div>
                        <div className="left">
                            <p className="m-0 copon-date">
                                {getHumanDate(code.created)}
                            </p>
                        </div>
                    </div>) : 'لا يوجد اكواد سابقه') : <Placeholder />
                } */}
                {
                    allStudentsCode ? (allStudentsCode.length > 0 ? allStudentsCode.map(code => <div className='copon-row'>
                        <div className="right">
                            {
                                code.user ?
                                    <>
                                        <p className="code m-0">
                                            {code.user.username}
                                        </p>
                                        <p className='m-0'>
                                            السنه الدراسيه : {code.year && code.year.name}
                                        </p>
                                        <p className='m-0'>
                                            المكان: {code.place && code.place.name}
                                        </p>
                                        <p className='m-0'>
                                            نوع التعليم:  {code.type_education && code.type_education.name}
                                        </p>
                                    </> :
                                    <>
                                        <p className="code m-0">
                                            {code.code}
                                        </p>
                                        <p className="m-0 me-2">
                                            عدد مرات المشاهده : <span className={`badge ${code.view_counter > 0 ? 'bg-primary' : 'bg-danger'}`} >{code.view_counter}</span>
                                        </p>
                                        <p className="m-0">
                                            {code.lesson.name}
                                        </p>
                                    </>
                            }
                        </div>
                        {
                            code.created && <div className="left">
                                <p className="m-0 copon-date">
                                    {getHumanDate(code.created)}
                                </p>
                            </div>
                        }
                    </div>) : <p className='text-center'> لا يوجد اكواد سابقه </p>) : <Placeholder />
                }
            </div>
            <div className="pagination-btn">
                <button onClick={() => setCurrentPage((prev) => prev - 1)} className={`prev ${currentPage > 1 ? '' : 'disable'}`} disabled={currentPage > 1 ? false : true}> السابق </button>
                <button className={`next ${nextPage ? '' : 'disable'}`} disabled={nextPage ? false : true} onClick={() => setCurrentPage((prev) => prev + 1)}> التالي </button>
            </div>
        </div>
    </>
}
