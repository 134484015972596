import React from 'react'
import UnitCourseBox from '../Unit/UnitCourseBox'

export default function SectionContainer(props) {
  return <>
    <div className="dashboard-section-container">
      <h6>
        {props.sectionTitle}
      </h6>

      <div className="content">
      {props.children}
      </div>
    </div>
  </>
}
