import React from 'react'
import NotificationSection from '../../../Components/Dashboard/Notifications/NotificationSection'

export default function DashboardNotififcations() {
  return <>
    <div className="container-fluid dashboard-notifications-page">
        <h1>
        التنبيهات ( الاشعارات )
        </h1>
        <div className='new-notification'>
            <h6>انشاء اشعار جديد</h6>
            <form action="" className='notification-data'>
                <div className="top">
                    <div>
                        <label htmlFor="notification-type">
                        الاشعار يتحدث عن ( اختياري )
                        </label>
                        <select name="" id="notification-type">
                            <option value="">أختر الفئة</option>
                        </select>
                    </div>
                    <div>
                        <select name="" id="">
                            <option value="">أختر اسم الكورس / الدرس / الفيديو</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="publish-date">تاريخ النشر</label>
                        <input type="date" name="" id="publish-date" />
                    </div>
                </div>
                <div className="middle">
                    <div>
                        <p>
                        القسم
                        </p>
                        <ul>
                            <li>علمي</li>
                            <li>أدبي</li>
                        </ul>
                    </div>
                    <div>
                        <p>الصف</p>
                        <ul>
                            <li>الاول الثانوي</li>
                            <li>الثاني الثانوي</li>
                            <li>الثالث الثانوي</li>
                        </ul>
                    </div>
                    <div>
                        <p>تحديد الاشعار الى </p>
                        <ul>
                            <li>المشتركين في محتوي الاشعار</li>
                            <li>الجميع</li>
                        </ul>
                    </div>
                </div>
                <div className='bottom'>
                    <label htmlFor='content'>محتوى الاشعار</label>
                    <textarea name="" id="content"></textarea>
                </div>
                <div className='create'>
                    <button>
                    إنشاء الاشعار
                    </button>
                </div>
            </form>
        </div>
        <div className="notifications">
            <h6>الاشعارات السابقة</h6>
            <NotificationSection date={'8/4/2024'}>
                <div className='notification-row-box'>
                    <div className='image'>
                        <img src="" alt="" className='w-100 h-100'/>
                    </div>
                    <div className='data'>
                        <h6 className='title'>أسم الكورس</h6>
                        <p className='m-0 content'>
                        شرح تفصيلي لالمحتوا الداخلي للكورس. 
                        </p>
                        <p className='m-0 date'>
                        منذ 1 ساعة
                        </p>
                    </div>
                </div>
            </NotificationSection>
        </div>
    </div>
  </>
}
