import React , {useContext} from 'react'
import { Link, useLocation } from 'react-router-dom'
import { darkMode } from '../../../Context/DarkModeContext';

export default function MobileMenu(props) {

    const location = useLocation();
    let { ToggleDarkTheme, darkTheme } = useContext(darkMode)

    return <>
        <div className={`dashboard-mobile-menu-container ${props.open ? 'open' : ''}`}>
            <div className="menu-header">
                <button className='close-menu' onClick={props.methoud}>
                    <i className="fa-solid fa-xmark"></i>
                </button>
                <button className="dark-theme" onClick={ToggleDarkTheme}>
                    <i className="fa-regular fa-moon"></i>
                </button>
            </div>
            <ul className='menu-links'>
                <li className={location.pathname === '/dashboard' ? 'active' : ''}>
                    <Link to={'/dashboard'}>
                        <i className="fa-solid fa-house"></i>
                        <p className='m-0 p-0'>
                            الرئيسي
                        </p>
                    </Link>
                </li>
                <li className={location.pathname === '/dashboard/students' ? 'active' : ''}>
                    <Link to={'/dashboard/students'}>
                        <i className="fa-solid fa-users"></i>
                        <p className='m-0 p-0'>
                            الطلاب
                        </p>
                    </Link>
                </li>
                <li className={location.pathname === '/dashboard/categories' ? 'active' : ''}>
                    <Link to={'/dashboard/categories'}>
                        <i className="fa-solid fa-layer-group"></i>
                        <p className='m-0 p-0'>
                            الأقسام
                        </p>
                    </Link>
                </li>
                <li className={location.pathname === '/dashboard/courses' ? 'active' : ''}>
                    <Link to={'/dashboard/courses'}>
                        <i className="fa-solid fa-table"></i>
                        <p className='m-0 p-0'>
                            الكورسات
                        </p>
                    </Link>
                </li>
                <li className={location.pathname === '/dashboard/units' ? 'active' : ''}>
                    <Link to={'/dashboard/units'}>
                        <i className="fa-solid fa-share-nodes"></i>
                        <p className='m-0 p-0'>
                            الوحدات
                        </p>
                    </Link>
                </li>
                <li className={location.pathname === '/dashboard/lessons' ? 'active' : ''}>
                    <Link to={'/dashboard/lessons'}>
                        <i className="fa-solid fa-quote-right"></i>
                        <p className='m-0 p-0'>
                            الدروس
                        </p>
                    </Link>
                </li>
                <li className={location.pathname === '/dashboard/videos' ? 'active' : ''}>
                    <Link to={'/dashboard/videos'}>
                        <i className="fa-solid fa-desktop"></i>
                        <p className='m-0 p-0'>
                            الفيديوهات
                        </p>
                    </Link>
                </li>
                <li className={location.pathname === '/dashboard/exams' ? 'active' : ''}>
                    <Link to={'/dashboard/exams'}>
                        <i className="fa-solid fa-paste"></i>
                        <p className='m-0 p-0'>
                            الامتحانات
                        </p>
                    </Link>
                </li>
                <li className={location.pathname === '/dashboard/subscriptions' ? 'active' : ''}>
                    <Link to={'/dashboard/subscriptions'}>
                        <i className="fa-solid fa-user-plus"></i>
                        <p className='m-0 p-0'>
                            الاشتراكات والفواتير
                        </p>
                    </Link>
                </li>
                <li className={location.pathname === '/dashboard/create-copon' ? 'active' : ''}>
                    <Link to={'/dashboard/create-copon'}>
                        <i className="fa-solid fa-ticket-simple"></i>
                        <p className='m-0 p-0'>
                            الأكواد
                        </p>
                    </Link>
                </li>
                <li className={location.pathname === '/dashboard/users' ? 'active' : ''}>
                    <Link to={'/dashboard/users'}>
                        <i className="fa-solid fa-person"></i>
                        <p className='m-0 p-0'>
                            المستخدمين
                        </p>
                    </Link>
                </li>
                <li className={location.pathname === '/dashboard/notifications' ? 'active' : ''}>
                    <Link to={'/dashboard/notifications'}>
                        <i className="fa-solid fa-bell"></i>
                        <p className='m-0 p-0'>
                            التنبيهات (الاشعارات)
                        </p>
                    </Link>
                </li>
                <li className={location.pathname === '/dashboard/attachments' ? 'active' : ''}>
                    <Link to={'/dashboard/attachments'}>
                        <i className="fa-solid fa-book-bookmark"></i>
                        <p className='m-0 p-0'>
                            المذكرات
                        </p>
                    </Link>
                </li>
            </ul>
        </div>

    </>
}
