import React, { useContext, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import config from '../../../config'
import { auth } from '../../../Context/AuthStore'
import Placeholder from '../../../Components/Used/Placeholder'
import { useParams } from 'react-router-dom'
import Validator from '../../../Validator'
import Swal from 'sweetalert2'
import QuizQuestions from '../../../Components/Dashboard/Quizes/QuizQuestions'

export default function QuizQuestionAnswers() {

    var refdiv = useRef(null);
    var rte = useRef(null) ;

    const {quiz_id} = useParams();
    const {userAuth} = useContext(auth)
    const [examQuestionsData, setExamQuestionsData] = useState(false)
    const [newQuestionData, setNewQuestionData] = useState({
        text: '' ,
        grade: ''
    })

    const updateNewQuestionData = (e) => {
        let data = {...newQuestionData} ;
        data[e.target.name] = e.target.value ;
        setNewQuestionData(data)
    }

    const getQuestionsData = () => {
        axios.get(`${config.basURL}dashboard/quiz-questions/${quiz_id}` , {
            headers: {
                'Authorization' : config.ApiKey ,
                'auth' : `Bearer ${userAuth}`
            }
        }).then(res => {
            setExamQuestionsData(res.data)
            console.log(res.data)
        }).catch(err => console.log(err))
    }

    const addNewQuestion = (e) => {
        e.preventDefault()
        let data = {...newQuestionData}
        data.text = rte.current.getHTMLCode()
        let validation = new Validator;
        validation.setKey('text').setValue(data.text).required()
        validation.setKey('grade').setValue(data.grade).required()

        if(validation.haveError) {
            Swal.fire({
                text: 'برجاء ملئ جميع الحقول' ,
                icon : 'error'
            })
            return;
        }

        axios.post(`${config.basURL}dashboard/quiz-questions/${quiz_id}` , data , {
            headers: {
                'Authorization' : config.ApiKey ,
                'auth' : `Bearer ${userAuth}`
            }
        }).then(res => {
            getQuestionsData();
            Swal.fire({
                text: 'تم الاضافه بنجاح', 
                icon: 'success'
            })
        }).catch(err => console.log(err))
    }

    useEffect(() => {
        rte.current = new window.RichTextEditor(refdiv.current);
        rte.current.setHTMLCode("");
    } , [])

    useEffect(() => {
        getQuestionsData()
    } , [userAuth])

    return <>
        <div className="container-fluid dashboard-queastions-answers" >
            <h1>
                اسئله الامتحانات
            </h1>

            <form action="" className='add-new-question' onSubmit={addNewQuestion}>
                <h5 className='mb-3'> اضافه سؤال جديد </h5>
                {/* <input type="text" name='text'  placeholder='السؤال' onChange={updateNewQuestionData}/> */}
                <div ref={refdiv} ></div>
                <input type="text" name='grade'  placeholder='درحه السؤال' onChange={updateNewQuestionData}/>
                <button className='add-question'> اضافه </button>
            </form>

            <h2 className='exam-name'>
                أسم الامتحان
            </h2>
            <div className="content-container">
                {
                    examQuestionsData ? (examQuestionsData.length > 0 ? examQuestionsData.map(question => <QuizQuestions refresh={getQuestionsData} examId={quiz_id} data={question} />)  :  <p className='w-100 text-center'> لا توجد اسئله في هذا الامتحان </p>) : <Placeholder />
                }
            </div>
        </div>
    </>
}
