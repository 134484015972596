import React from 'react'
import Header from '../../Components/Dashboard/Header'
import { Outlet } from 'react-router-dom'
import '../../Dashboard.css';
import Sidebar from '../../Components/Dashboard/Sidebar';

export default function DashboardLayout() {
  return <>
    <Header />
    <div className='dashboard-layout-content-container'>
      <Sidebar />
      <Outlet />
    </div>
  </>
}
