import React, { useContext, useEffect, useState } from 'react'
import dashboardExamImage from '../../../assets/dashboard-exams.svg';
import ExamsRowBox from '../../../Components/Dashboard/Exams/ExamsRowBox';
import axios from 'axios'
import config from '../../../config'
import { auth } from '../../../Context/AuthStore'
import Placeholder from '../../../Components/Used/Placeholder'
import { useParams } from 'react-router-dom'
import Validator from '../../../Validator'
import Swal from 'sweetalert2'

export default function Exams() {

    const { id, type } = useParams()
    const { userAuth } = useContext(auth)
    const [examsData, setExamsData] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [newExamData, setNewExamData] = useState({
        name: '' ,
        description: '' ,
        is_many_times: false ,
        display_questions: '' ,
        exam_minutes: '' ,
        course: null ,
        lesson: null,
        result_date: ''
    })

    const updateNewExamData = (e) => {
        let data = {...newExamData} ;
        data[e.target.name] = e.target.value ;
        setNewExamData(data)
    }

    const validate = () => {
        let valid = new Validator ;
        valid.setKey('name').setValue(newExamData.name).required()
        valid.setKey('description').setValue(newExamData.description).required()
        valid.setKey('display_questions').setValue(newExamData.display_questions).required()
        valid.setKey('exam_minutes').setValue(newExamData.exam_minutes).required()
        // valid.setKey('result_date').setValue(newExamData.result_date).required()
        return valid
    }

    const createNewExam = (e) => {
        e.preventDefault();
        setIsLoading(true)
        let validation = validate();

        if(validation.haveError) {
            Swal.fire({
                text: 'يوجد خطاء' ,
                icon : 'error'
            })
            return;
        }

        let dataToSend = {...newExamData}
        if(type === 'course'){
            dataToSend.course = Number(id) ;
        }else if(type === 'lesson') {
            dataToSend.lesson = Number(id) ;
        }

        axios.post(`${config.basURL}dashboard/${type}-exam-overview/${id}` , dataToSend , {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`,
            }
        }).then(res => {
            setIsLoading(false)
            getExamsData()
            Swal.fire({
                text: 'تم الاضافه بنجاح' ,
                icon : 'success'
            })
        }).catch(err => {
            setIsLoading(false)
            console.log(err)
        })
    }

    const getExamsData = () => {
        axios.get(`${config.basURL}dashboard/${type}-exam-overview/${id}`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`,
            }
        }).then(res => {
            setExamsData(res.data.exam_course)
        }).catch(err => console.log(err))
    }

    useEffect(() => {
        getExamsData()
    }, [userAuth])

    return <>
        <div className="container-fluid dashboard-exams-page">
            <h1>
                الامتحانات
            </h1>
            <form className="new-exam" onSubmit={createNewExam}>
                <div className='inputs'>
                    <div className='top'>
                        <select name="is_many_times" id="" onChange={updateNewExamData}>
                            <option>
                                اماكنبه الدخول اكثر من مره
                            </option>
                            <option value={true}> نعم </option>
                            <option value={false}> لا </option>
                        </select>
                        <input type="text" name='name' placeholder='اسم الامتحان / التدريب' onChange={updateNewExamData}/>
                    </div>
                    <div className="middle">
                        <input type="text" name='display_questions' placeholder='عدد الاسئله' onChange={updateNewExamData}/>
                        <input type="text" name='exam_minutes' placeholder='مده الامتحان' onChange={updateNewExamData}/>
                    </div>
                    <div className="middle">
                        <label htmlFor="result_date"> ( اختياري ) وقت ظهور النتيجه </label>
                        <input type="date" name='result_date' id='result_date' onChange={updateNewExamData}/>
                    </div>
                    <div className="bottom">
                        <textarea name="description" id="" placeholder='وصف الامتحان' onChange={updateNewExamData}></textarea>
                    </div>
                    <button className='save-data'>
                        {
                            isLoading ? <i className="fa-solid fa-circle-notch fa-spin"></i>  : 'حفظ البيانات'
                        }
                    </button>
                </div>
                <div className="image">
                    <img src={dashboardExamImage} alt="" className='w-100 h-100' />
                </div>
            </form>

            <div className='content-container'>
                {
                    examsData ? (examsData.length ? examsData.map(exam => <ExamsRowBox type={type} data={exam} courseId={id} refresh={getExamsData}/>) : <p className='text-center w-100'> لا يوجد امتحانات </p>) : <Placeholder />
                }

            </div>
        </div>
    </>
}
